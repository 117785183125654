.box {
  width: 99%;
  padding: 0 0.5%;
}

.title {
  font-weight: 600;
  font-size: 0.9rem;
  padding: 1rem 0 1rem 1rem;
}

.list {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  height: 25.5rem;
  position: relative;
}

.cron {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image_box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
}

.item_same {
  width: 19%;
  height: 6rem;
  margin: 0.5%;
  border-radius: 0.4rem;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
}

.item {
  border: #e4e4e7 solid 0.15rem;
}

.item_hover {
  border: #5eead4 solid 0.15rem;
  cursor: pointer;
}

.item:hover {
  border: #5eead4 solid 0.15rem;
  cursor: pointer;

  .content {
    display: block;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentSame {
  width: calc(100% - 0.4rem);
  text-align: center;
  position: absolute;
  bottom: 0;
  color: white;
  background-color: rgba($color: #000000, $alpha: 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.8rem;
  padding: 0.1rem 0.2rem;
}

.content {
  display: none;
}

.content_hover {
  display: block;
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1% 0;
}
