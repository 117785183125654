.box {
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.avatarBox {
  width: 12rem;
  border-radius: 1rem;
  background-color: #fff;

  .imgBox {
    height: 12rem;
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    padding: 5%;
    box-sizing: border-box;
    position: relative;

    .cancle {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      cursor: pointer;
      color: black;
      position: absolute;
    }

    .cancle:hover {
      position: absolute;
      right: 0;
      z-index: 1;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.399);
      position: absolute;
    }

    .avatarImg {
      border-radius: 1rem;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: cover;
    }
  }
}

.avatarName {
  text-align: center;
  width: 100%;
  overflow: hidden;
  padding: 1% 5% 0;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
