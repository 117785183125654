.switch {
  position: relative;
  .switchCheckbox {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border: 0;
    appearance: none;
  }
  .switchContainer {
    position: relative;
    width: 45px;
    height: 24px;
    border-radius: 28px;
    background: #a7aaa6;
    z-index: 0;
    margin: 0;
    padding: 0;
    appearance: none;
    border: 0;
    transition: all 0.2s;
  }
  .switchContainer::after {
    position: absolute;
    left: 2px;
    top: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    content: "";
    transition: all 0.2s;
  }
  .switchContainerChecked {
    background-color: #00cea3;
  }
  .switchContainerChecked::after {
    transform: translateX(21px);
  }
}
