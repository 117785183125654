@font-face {
  font-family: "iconfont";
  src: url("iconfont.woff2?t=1661214478966") format("woff2"),
    url("iconfont.woff?t=1661214478966") format("woff"),
    url("iconfont.ttf?t=1661214478966") format("truetype");
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wenhao1:before {
  content: "\e617";
}

.icon-lianxikefu:before {
  content: "\e624";
}

.icon-send-plane-fill:before {
  content: "\edd4";
}

.icon-qm:before {
  content: "\e646";
}

.icon-htmal5icon31:before {
  content: "\e643";
}

.icon-sousuoxiao:before {
  content: "\e8d6";
}

.icon-diannao-tianchong:before {
  content: "\e641";
  font-size: 1.1rem;
}

.icon-sort-full:before {
  content: "\e65c";
}

.icon-24gf-folder:before {
  content: "\eac4";
}

.icon-wenhao:before {
  content: "\e69b";
  font-size: 1.1rem;
}

.icon-jiahao:before {
  content: "\e879";
}

.icon-yonghu:before {
  content: "\e60a";
}

.icon-xiaoxi:before {
  content: "\e62f";
  font-size: 1.2rem;
}

.icon-a-ziyuan663:before {
  content: "\e795";
  font-size: 1.1rem;
}
