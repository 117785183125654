.wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(241, 241, 241, 0.918);
  .tipText {
    display: flex;
    justify-content: center;
  }
  .loginBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 2.5rem 3rem;
    border-radius: 1rem;
    background-color: white;
    box-shadow: 0rem 0.02rem 0.05rem rgb(215, 215, 215);
    position: relative;
    .inputTopWrap {
      width: 25rem;
      height: 2.5rem;
      margin-top: 3rem;
      margin-bottom: 1rem;
      padding: 0 0.8rem;
      border: 0.01rem solid #d2d2d2;
      border-radius: 0.5rem;
    }
    .inputWrap {
      width: 25rem;
      height: 2.5rem;
      padding: 0 0.8rem;
      border: 0.01rem solid #d2d2d2;
      border-radius: 0.5rem;
    }
    .rememberMeWrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0.4rem;
      .rememberMeText {
        color: rgb(142, 142, 142);
        font-size: 0.9rem;
      }
    }
    .signInButton {
      width: 26.8rem;
      height: 2.5rem;
      margin-top: 0.6rem;
      background-color: rgb(72, 129, 228);
      border-radius: 0.5rem;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      color: white;
      font-size: 0.8rem;
      font-weight: 600;
      cursor: pointer;
    }

    .titleWrap {
      width: 29rem;
      height: 5rem;
      border-radius: 1rem;
      box-shadow: 0rem 0.02rem 0.05rem rgb(215, 215, 215);
      position: absolute;
      top: -1.5rem;
      left: 1.9rem;
      background-color: rgb(72, 129, 228);
      display: flex;
      align-items: center;
      justify-content: center;
      .titleText {
        color: white;
        font-size: 1.5rem;
      }
    }
  }
}
