.digitizing {
  display: flex;
  flex: 1 1;
  max-height: calc(100vh - 3rem);
  padding-top: 2.2rem;
  padding-left: 4.5rem;
  padding-right: 1rem;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0;
  }

  .list {
    display: flex;
    flex-direction: column;
  }

  .avatorList {
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100vh - 15rem);
    &::-webkit-scrollbar {
      width: 0;
    }

    .noData {
      margin-top: 2rem;
      color: #666;
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }

  .avatarAdd {
    border-radius: 1rem;
    width: 9rem;
    height: 2rem;
    background-color: #30cea3;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #fff;
    margin: 1rem 1rem 1rem 0;
  }

  input[type="file"] {
    display: none;
    opacity: 0;
  }

  .title {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .pagination {
    position: absolute;
    bottom: 3rem;
    width: 100%;
  }

  .paginationBox {
    display: flex;
    justify-content: center;
  }
}
