@font-face {
  font-family: "iconfont";
  src: url("../../fonts/iconfont.woff2?t=1661158283558") format("woff2"),
    url("../../fonts/iconfont.woff?t=1661158283558") format("woff"),
    url("../../fonts/iconfont.ttf?t=1661158283558") format("truetype");
}
.body {
  width: 100vw;
  height: 100vh;
  background-color: #f4f4f4;
  .navBar {
    background-color: white;
    position: absolute;
    height: 100vh;
    left: 0;
    z-index: 2;
    .navItem {
      margin-top: 7.6rem;
      padding-left: 2.5rem;
      padding-right: 6rem;
      display: flex;
      flex-direction: column;
      .titleFont {
        color: #000;
        font-size: 0.12rem;
        margin-bottom: 0.2rem;
      }
      .navBarList {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1.4rem;
        margin-left: 0.2rem;
        margin-bottom: 1.4rem;
        text-decoration: none;
        .defaultIcon {
          color: #000;
          font-size: 1rem;
          margin-right: 0.8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.8rem;
          height: 0.22rem;
        }
        .defaultNavName {
          color: #000;
          font-size: 1rem;
          font-weight: 400;
          width: 4rem;
          height: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .clickIcon {
          color: #30cea3;
          font-size: 1rem;
          margin-right: 0.8rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 0.8rem;
          height: 0.22rem;
        }
        .clickNavName {
          color: #30cea3;
          font-size: 1rem;
          font-weight: 400;
          width: 4rem;
          height: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .main {
    width: calc(100vw - 14.5rem);
    height: 100%;
    position: fixed;
    left: 14.5rem;
  }
}
