.default {
  .top {
    background-color: #f4f4f4;
    z-index: 5;
    display: flex;
    flex-direction: column;
    width: calc(100vw - 14.5rem);
    .title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      z-index: 5;
      margin-top: 1.3rem;
      background-color: #f4f4f4;
      .titleLeft {
        margin-left: 2.9rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        .clickTitle {
          color: #000;
          margin-left: 1.6rem;
          padding: 0 0 0.5rem;
          font-size: 1.2rem;
          font-weight: 500;
          border-bottom-style: solid;
          width: 4.8rem;
          height: 1.5rem;
          text-decoration: none;
        }
        .defaultTitle {
          color: gray;
          padding: 0 0 0.5rem;
          font-weight: 500;
          font-size: 1.2rem;
          margin-left: 1.6rem;
          width: 4.8rem;
          height: 1.5rem;
          text-decoration: none;
        }
      }
      .titleRight {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 1.5rem;
        .userIcon {
          font-size: 2em;
          color: #b3b3b3;
          position: relative;
        }
      }
    }
  }
}
