.buttonWrap {
  display: flex;
  cursor: pointer;
  position: relative;
  .btn {
    z-index: 0;
    color: #000;
    display: flex;
    width: 1.8rem;
    height: 1.8rem;
  }

  .menuWrap {
    background-color: white;
    position: absolute;
    top: 1.5rem;
    z-index: 10;
    box-shadow: 0rem 0rem 0.2rem rgb(210, 210, 210);
    .menuItemWrap {
      display: flex;
      justify-content: center;
      font-size: 0.8rem;
      color: #333;
      cursor: pointer;
    }
  }
}
