.box {
  width: 100%;
  height: 100%;

  .addAndSearch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    .buttom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .refreshText {
        width: 2.5rem;
        height: 2.5rem;
        z-index: 1;
      }
      .addButtom {
        border-radius: 1rem;
        width: 9rem;
        height: 2rem;
        background-color: #30cea3;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 4.5rem;
        margin-right: 1rem;
        text-decoration: none;
        .addIcon {
          color: white;
          font-size: 0.9rem;
          font-weight: 600;
        }
        .addFont {
          margin-left: 0.4rem;
          color: white;
          font-size: 0.9rem;
          font-weight: 600;
          width: 5.5rem;
        }
      }
    }

    .search {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #efefef;
      margin-right: 2.5rem;
      padding: 0.7rem 1rem 0.7rem 1rem;
      border-radius: 0.3rem;
      .searchInput {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: 0.01rem none;
        border-radius: 0.5rem;
        outline: none;
      }
      .searchIcon {
        font-size: 1rem;
        font-weight: 600;
        color: #b4b4b4;
      }
    }
  }
  .vedio {
    display: flex;
    flex: 1;
    padding-top: 1rem;
    padding-left: 4.5rem;
    padding-right: 1rem;
    flex-wrap: wrap;
    flex-direction: row;
    overflow-y: scroll;
    max-height: calc(100vh - 16rem);
    overflow-x: hidden;

    .projectWrap {
      margin: 0.5rem 2rem 1.5rem 0;
      width: 13rem;
      box-sizing: content-box;

      .videoWrap {
        width: 100%;
        height: 8rem;
        box-sizing: border-box;
        background-color: #fff;
        padding: 0.4rem;
        border-radius: 0.5rem;
        margin-bottom: 0.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .videoBox {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0.25rem;

          .video {
            width: 100%;
            height: 100%;
          }
        }
        .pendingBox {
          width: 13rem;
          height: 7.5rem;
          background-color: #f4f4f4;
          border-radius: 0.2rem;
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: none;
        }
      }

      .videoWrap:hover {
        box-shadow: 0.01rem 0.01rem 1rem rgb(194, 194, 194);
        cursor: pointer;
      }

      .videoMessageWrap {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: flex-start;
        justify-content: space-between;

        .videoContextTop {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          .videoTitle {
            font-size: 0.9rem;
            font-weight: 500;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .videoContextBottom {
          .videoTime {
            font-size: 0.7rem;
            color: #646464;
          }
        }
      }
    }

    .tipWrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100vw;
      z-index: 0;
      .tip {
        font-size: 1rem;
        color: #d7d7d7;
        font-weight: 500;
        display: flex;
        justify-content: center;
        margin-top: 15rem;
      }
    }
  }

  .vedio::-webkit-scrollbar {
    display: none;
  }

  .pagination {
    margin-top: 2rem;
    color: #666;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
