$switchHeight: 5%;
$threeButtonPadding: 0 1rem;
$threeButtonHeight: 1.5rem;
$threeButtonLineHeight: 1.5rem;
$activeColor: #30cea3;

.body {
  padding: 2rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .titleLeft {
      display: flex;

      .arrow {
        font-size: 1.4rem;
        font-weight: 500;
        height: 1.2rem;
        line-height: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        color: #000;
      }

      .titleLeftFont {
        font-size: 1rem;
        font-weight: 600;
        height: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        background-color: #f4f4f4;
      }
    }

    .titleRight {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .importText {
        border: 0.1rem solid #cecece;
        background-color: white;
        border-radius: 1rem;
        font-size: 0.6rem;
        padding: $threeButtonPadding;
        font-weight: 500;
        cursor: pointer;
        height: $threeButtonHeight;
        line-height: $threeButtonLineHeight;
      }

      .saveDraft {
        border: 0.1rem solid #cecece;
        background-color: white;
        border-radius: 1rem;
        font-size: 0.6rem;
        padding: $threeButtonPadding;
        font-weight: 500;
        margin-left: 0.8rem;
        margin-right: 0.8rem;
        cursor: pointer;
        height: $threeButtonHeight;
        line-height: $threeButtonLineHeight;
      }

      .generateVideo {
        border: 0.1rem solid #30cea3;
        background-color: #30cea3;
        color: white;
        border-radius: 1rem;
        font-size: 0.6rem;
        padding: $threeButtonPadding;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
        height: $threeButtonHeight;
        line-height: $threeButtonLineHeight;
      }
    }
  }

  .editBackground {
    background-color: white;
    box-shadow: 0 0 0.6rem rgba($color: gray, $alpha: 0.2);
    margin-top: 1.4rem;
    display: flex;
    flex-direction: row;
    height: 100vh;

    .editLeft {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: white;
      flex: 0 1 40%;
      overflow: auto;

      .topLeft {
        border: 0.01rem #f0f0f0;
        border-style: none solid none none;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-height: 30%;
        padding: 1.8rem;
        flex: 1;
        box-sizing: border-box;

        .videoParam {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .videoScale {
            display: flex;
            align-items: center;
          }
        }

        .videoPreview {
          margin-top: 2rem;
          width: 100%;
          height: 18rem;
          background: #d8d8d8;
        }
      }

      .buttomLeft {
        border: 0.01rem #f0f0f0;
        border-style: solid solid none none;
        display: flex;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1.6rem;
        height: $switchHeight;

        .leftText {
          font-size: 0.9rem;
        }

        .switch {
          margin-left: 0.4rem;
        }
      }
    }

    .editRight {
      display: flex;
      flex-direction: column;
      background-color: white;
      flex: 1;

      .topRight {
        border: 0.01rem #f0f0f0;
        border-style: none none none none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        min-width: 6rem;
        flex: 1;

        .inputText {
          height: 100%;
          font-size: 1rem;
          display: flex;
          padding: 1.8rem;
          width: calc(100% - 3.6rem);
          border: none;
          outline: medium;
          resize: none;
          overflow-y: scroll;
        }

        .inputText::-webkit-scrollbar {
          background-color: white;
          width: 0.6rem;
        }

        .inputText::-webkit-scrollbar-thumb {
          border-radius: 3rem;
          background: #e4e4e4;
          -webkit-box-shadow: inset 0 0 0.3rem rgba(205, 205, 205, 0.3);
        }
      }

      .buttomRight {
        border: 0.01rem #f0f0f0;
        border-style: solid solid none none;
        display: flex;
        align-items: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1.6rem;
        height: $switchHeight;

        .rightText {
          font-size: 0.9rem;
        }

        .switch {
          margin-left: 0.4rem;
        }
      }
    }
  }
}

.dialogVideoPreViewLayout {
  flex: 1;
  padding-right: 1%;
  padding-top: 1.5rem;
  box-sizing: border-box;
  border-right: 1px solid #ededed;

  .videoPreViewTitle {
    font-size: 0.85rem;
    font-weight: 500;
    color: #000;
    margin-bottom: 1rem;
  }

  .dialogVideoPreView {
    height: 18rem;
    background-color: #eee;
    border-radius: 0.25rem;
  }
}
