.uploadBox {
  display: flex;

  .avatarAdd {
    width: 20rem;
    height: 10rem;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 1rem 1rem 1rem 0;
    color: #000;
    border: 0.02rem solid #999;
    z-index: 1;
    position: relative;

    .img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }

    .icon {
      color: #999;
      position: absolute;
      right: -0.8rem;
      top: -0.8rem;
    }
  }
}

input[type="file"] {
  display: none;
  opacity: 0;
}

.enterInput {
  margin-right: 1rem;
  width: 15rem;
}

label.uploadBtn {
  background-color: #30cea3 !important;
  color: #fff;
}
