.modalBox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 50;

  .videoBoxWrap {
    display: flex;
    position: relative;
    .cancle {
      cursor: pointer;

      .icon {
        z-index: 60;
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        top: -1.25rem;
        right: -1.25rem;
        color: rgb(255, 255, 255);
      }
    }
    .cancle:hover {
      cursor: pointer;
      .icon {
        z-index: 60;
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        top: -1.25rem;
        right: -1.25rem;
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .videoBox {
      width: 49.5rem;
      height: 28rem;
    }
    .textBox {
      width: 49.5rem;
      max-height: 28rem;
      overflow-y: scroll;
      padding: 1rem;
      border-radius: 0.2rem;
      background-color: rgb(248, 248, 248);
      display: flex;
      justify-content: flex-start;
      .textWrap {
        color: #333333;
        line-height: 2rem;
        font-size: 1.2rem;
      }
    }
    .textBox::-webkit-scrollbar {
      display: none;
    }
  }
}
